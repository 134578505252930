import React from "react";
import Layout from "../components/Layout";
import Helmet from "react-helmet";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <Helmet title="Page Not Found | Amos Institute">
      <meta name="robots" content="noindex" />
    </Helmet>
    <section className="container mx-auto pb-4 pt-6">
      <h1 className="text-3xl md:text-5xl px-0 lg:px-14 xl:px-18 max-w-screen-sm font-bold pb-4">
        Page not found...
      </h1>
      <p className="pb-10 text-gray-800 text-lg">
        <Link to="/">Return to homepage →</Link>
      </p>
    </section>
  </Layout>
);

export default NotFoundPage;
